
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
// import { twoDecimalOrNull } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { ProductDetail } from '@/types/product'
import { FileInfo } from '@/types/uploadFile'

@Component({
  name: 'ProductAdd',
  components: { UploadFile }
})
export default class extends Vue {
  private title = '新增产品'
  private unitList: Array<{ unitId: string; unitName: string }> = []
  private supplierList: Array<{ supplierId: string; supplierName: string }> = []
  private attributeList: Array<{ attributeId: string; attributeName: string }> = []
  private productTypeList: Array<{ productTypeId: string; productTypeName: string }> = []
  private typeList: Array<{ typeId: string; typeName: string }> = []
  private productRelList: Array<{ relProductId: string; productName: string }> = []
  private submitShow = false
  private info: ProductDetail = {
    productName: '',
    productModel: '',
    unitId: '',
    supplierId: '',
    productTypeId: '',
    attributeId: '',
    sdescribe: '',
    verificationStatus: '1',
    productSupplierLevel: '',
    parameterInformation: '',
    resourceList: [],
    fileList: [],
    productRelList: []
  }

  private rules = {
    productName: [
      { required: true, message: '请输入产品名称', trigger: ['blur', 'change'] }
    ],
    productModel: [
      { required: true, message: '请输入产品型号', trigger: ['blur', 'change'] }
    ],
    unitId: [
      { required: true, message: '请选择计量单位', trigger: ['blur', 'change'] }
    ],
    supplierId: [
      { required: true, message: '请选择供应商', trigger: ['blur', 'change'] }
    ],
    productTypeId: [
      { required: true, message: '请选择产品类型', trigger: ['blur', 'change'] }
    ],
    attributeId: [
      { required: true, message: '请选择产品属性', trigger: ['blur', 'change'] }
    ],
    parameterInformation: [
      { required: true, message: '请输入参数信息', trigger: ['blur', 'change'] }
    ]
  }

  private isUploadFile = false
  private file: { typeId: string; fileList: Array<FileInfo> } = {
    typeId: '',
    fileList: []
  }

  private fileRules = {
    typeId: [
      { required: true, message: '请选择附件类型', trigger: ['blur', 'change'] }
    ],
    fileList: [
      { required: true, message: '请添加附件', trigger: ['blur', 'change'] }
    ]
  }

  get productId () {
    return this.$route.params.id || ''
  }

  get parentProductId () {
    return this.$route.params.parentId || '0'
  }

  created () {
    this.getUnitList()
    this.getSupplierList()
    this.getAttributeList()
    this.getProductTypeList()
    this.getFileTypeList()
    this.getProductRelList()
    if (this.productId) {
      this.title = '编辑产品'
      this.getDetail()
    }
  }

  getUnitList () {
    this.$axios.get(this.$apis.product.selectProductUnitByPage).then((res) => {
      this.unitList = res.list || []
    })
  }

  getSupplierList () {
    this.$axios.get(this.$apis.supplier.list).then((res) => {
      this.supplierList = res.list || []
    })
  }

  getAttributeList () {
    this.$axios.get(this.$apis.product.selectProductAttributeByPage).then((res) => {
      this.attributeList = res.list || []
    })
  }

  getProductTypeList () {
    this.$axios.get(this.$apis.product.selectProductTypeByPage).then((res) => {
      this.productTypeList = res.list || []
    })
  }

  getFileTypeList () {
    this.$axios.get(this.$apis.product.selectProductFileTypeByPage).then((res) => {
      this.typeList = res.list || []
    })
  }

  getProductRelList () {
    this.$axios.get(this.$apis.product.selectOtherProductList, {
      productId: this.productId
    }).then((res) => {
      this.productRelList = res || []
    })
  }

  // 产品详情
  getDetail () {
    this.$axios.get(this.$apis.product.selectNewProductByProductId, {
      productId: this.productId
    }).then((res) => {
      this.info = res
    })
  }

  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  // 附件上传成功返回
  onFileSuccess (file: FileInfo) {
    this.file.fileList.push(file)
  }

  // 附件删除
  onFileRemove (key: string) {
    const num = this.file.fileList.findIndex(item => item.fileUrl === key)
    this.file.fileList.splice(num, 1)
  }

  // commitFile
  commitFile () {
    (this.$refs.fileForm as ElForm).validate((valid) => {
      if (valid) {
        const index = this.typeList.findIndex(item => item.typeId === this.file.typeId)
        const typeName = this.typeList[index].typeName
        this.file.fileList.forEach(item => {
          this.info.fileList.push({
            typeId: this.file.typeId,
            typeName: typeName,
            fileName: item.fileName,
            fileSize: this.formatSize(item.fileSize as number) || '',
            fileUrl: item.filePrefix + item.fileUrl
          })
        })
        this.resetFile()
      }
    })
  }

  formatSize (size: number): string {
    let sizeInfo = ''
    if (size < 1024) {
      sizeInfo = size + 'B'
    } else if (size < 1024 * 1024) {
      sizeInfo = (size / 1024).toFixed(2) + 'K'
    } else {
      sizeInfo = (size / 1024 / 1024).toFixed(2) + 'M'
    }
    return sizeInfo
  }

  beforeCloseFileUpload (done: Function) {
    this.resetFile()
    done()
  }

  // resetFile
  resetFile () {
    (this.$refs.fileForm as ElForm).resetFields()
    ;(this.$refs.uploadFile as any).reset()
    this.isUploadFile = false
    this.file = {
      typeId: '',
      fileList: []
    }
  }

  onFileDelete (index: number) {
    this.info.fileList.splice(index, 1)
  }

  handleClose (index: number) {
    this.info.productRelList.splice(index, 1)
  }

  // 提交
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.add()
      }
    })
  }

  // 新增及修改产品
  add () {
    this.submitShow = true
    const url = this.productId
      ? this.$apis.product.updateNewProduct
      : this.$apis.product.insertNewProduct
    this.$axios.post(url, {
      parentProductId: this.parentProductId,
      ...this.info
    }).then((res) => {
      this.submitShow = false
      if (this.parentProductId === '0' && res) {
        this.$router.push({ path: '/productList/result' + res })
      } else {
        this.$message.success('保存成功')
        this.$router.back()
      }
    }).finally(() => {
      this.submitShow = false
    })
  }
}
